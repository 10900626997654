<template>
  <v-dialog persistent :model-value="true" width="100%" max-width="600">
    <v-card>
      <v-card-title
        class="d-flex py-3 font-weight-medium bg-ap-dark-blue text-ap-white"
      >
        <img src="@/assets/images/fullAPLogo.jpg" style="max-height: 30px" />
        <v-spacer></v-spacer>
        Authentication
      </v-card-title>

      <v-card-text class="pa-6">
        <v-form ref="formEl" :disabled="isRedirecting">
          <div class="mb-1 font-weight-medium">
            Please select the functionality you want to test
          </div>

          <v-item-group
            v-model="form.feature"
            mandatory
            :disabled="isSystemsLoading || isRedirecting"
            class="mt-2 mb-6"
          >
            <v-container class="pa-0">
              <v-row>
                <v-col v-for="feature in features" :key="feature.path" cols="4">
                  <v-item v-slot="{ isSelected, toggle }">
                    <v-card
                      :color="isSelected ? 'primary' : 'ap-dark-blue-10'"
                      :class="{
                        'text-white': isSelected,
                        'text-ap-dark-blue': !isSelected,
                      }"
                      :loading="isSystemsLoading ? 'green' : false"
                      :style="{
                        cursor:
                          isSystemsLoading || isRedirecting
                            ? 'wait'
                            : 'pointer',
                      }"
                      @click="
                        isSystemsLoading || isRedirecting ? null : toggle()
                      "
                    >
                      <v-scroll-y-transition>
                        <div
                          class="d-flex align-center pa-6"
                          style="height: 120px"
                        >
                          <div class="flex-grow-1 text-center">
                            <div>
                              <v-icon
                                size="x-large"
                                :color="
                                  isSelected ? 'ap-dark-blue-10' : 'primary'
                                "
                                :icon="feature.icon"
                              ></v-icon>
                            </div>
                            <div class="mt-2 text-body-2 font-weight-medium">
                              {{ feature.label }}
                            </div>
                          </div>
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>

          <div class="mb-1 font-weight-medium">
            To which environment do you want to connect?
          </div>

          <v-autocomplete
            v-model="form.api"
            density="compact"
            variant="outlined"
            :items="provisionedSystems"
            :loading="isSystemsLoading"
            item-title="label"
            return-object
            hide-details
            @update:model-value="onChangeSelectedSystem"
          >
            <template #item="{ props, item }">
              <v-list-item
                v-bind="props"
                :title="item.title"
                class="py-2"
                :subtitle="`Created at ${item.raw.createdAt}`"
              ></v-list-item>
              <v-divider></v-divider>
            </template>
          </v-autocomplete>

          <div class="mb-1 mt-3 font-weight-medium">System API Key</div>
          <v-text-field
            v-model="form.apiKey"
            :rules="mandatoryRule"
            density="compact"
            variant="outlined"
            hide-details
          ></v-text-field>

          <div class="mb-1 mt-3 font-weight-medium">
            Which user do you want to impersonate?
          </div>

          <v-select
            v-model="form.user"
            density="compact"
            variant="outlined"
            :items="form.api?.users"
            :loading="isSystemsLoading"
            item-title="userName"
            return-object
            hide-details
          ></v-select>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="ap-dark-blue"
          variant="elevated"
          :disabled="isSystemsLoading"
          :loading="isRedirecting"
          :append-icon="mdiLoginVariant"
          @click="onSignIn"
        >
          Sign In
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
// import { useRouter } from "vue-router";
import { getTotpSecretKey, getProvisioningSystems } from "@/api/dev";
import { ref, reactive, onMounted } from "vue";
import {
  mdiWeatherSunsetUp,
  mdiLoginVariant,
  mdiMagnify,
  mdiHomeOutline,
  mdiHistory,
  mdiFileDocumentOutline,
} from "@mdi/js";
import { totp } from "@otplib/preset-browser";

const emit = defineEmits(["update:modelValue"]);

// const router = useRouter();
const formEl = ref(null);
const mandatoryRule = [(v) => !!v || ""];

const features = [
  {
    icon: mdiHomeOutline,
    label: "Home",
    path: "dashboard",
  },
  {
    icon: mdiFileDocumentOutline,
    label: "Details Page",
    path: "find-duplicates",
  },
  {
    icon: mdiHistory,
    label: "Record Audit Trail",
    path: "record-audit-trail",
  },
  {
    icon: mdiWeatherSunsetUp,
    label: "Heroku Setup",
    path: "general-settings/heroku",
  },
  {
    icon: mdiMagnify,
    label: "Search Before Create",
    path: "sbc",
  },
];

const form = reactive({
  feature: 0,
  api: null,
  apiKey: null,
  user: null,
});

const provisionedSystems = ref([]);
const isSystemsLoading = ref(false);
const isRedirecting = ref(false);

onMounted(async () => {
  isSystemsLoading.value = true;
  provisionedSystems.value = await getProvisioningSystems();
  isSystemsLoading.value = false;

  form.api = provisionedSystems.value.find(
    (el) => el.value === "ap-8c2786c1-b10a-4f6c-9983-c5d3607a5a99",
  );

  if (form.api) {
    form.apiKey = import.meta.env.VITE_PROVISIONING_SPA_API_TOKEN;
  } else {
    form.api = provisionedSystems.value[0];
  }

  form.user = form.api.users[0];
});

function onChangeSelectedSystem() {
  form.apiKey = null;
}

async function onSignIn() {
  const { valid: isValid } = await formEl.value.validate();

  if (isValid) {
    isRedirecting.value = true;

    let authJson = {
      packageVersion: 1,
      env: form.api.value,
      systemType: "sfdc",
      orgId: "",
      sessionId: "",
      origin: "https://activeprimeinc7-dev-ed.develop.lightning.force.com",
      url: "https://wwww.google.com",
      data: {
        entityKey: "", //"0013t00002MDvkkAAD",
        entityName: "Account", //"Account",
      },
      ...form.user,
    };

    localStorage.setItem("env", authJson.env);
    localStorage.setItem("apiKey", form.apiKey);

    if (features[form.feature].path !== "general-settings/heroku") {
      const { totpSecretKeyId, totpSecretKey } = await getTotpSecretKey();

      authJson = {
        ...authJson,
        totpSecretKeyId,
        totp: totp.generate(totpSecretKey),
      };
    }

    localStorage.setItem("authJson", JSON.stringify(authJson));

    window.location.pathname = features[form.feature].path;
    window.postMessage(authJson, "*");
    emit("update:modelValue", false);
  }
}
</script>
